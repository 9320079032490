import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { Icon } from '../../../common/icon'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage33() {
  const question = {
    title: 'Frage 34 von 35',
    headline: '',
    subline: 'Ein festes Team ist mir wichtig.',
  }

  const answers = [
    {
      name: 'festes-team',
      id: 'team',
      value: 'Absolut!',
      desc: '',
    },
    {
      name: 'festes-team',
      id: 'wechselnd',
      value: 'Ich arbeite gern in ständig wechselnden Teams.',
      desc: '',
    },
    {
      name: 'festes-team',
      id: 'springer',
      value: 'Ich arbeite gern im festen Team mit Springertätigkeiten.',
      desc: '',
    },
  ]

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-yellow-triangles">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="yellow"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="single"
              answers={answers}
              color="green"
              index={34}
            />
          </div>
        </section>

        <MatchingNavigation
          color="yellow"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/frage-35`}
          prev={`${AREA_CARE_PATH}/spielen/frage-33`}
          index={34}
        />

        <Icon position="2" name="network" />
      </main>
    </>
  )
}
